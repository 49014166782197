import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/gtx-profile.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-gtx.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/gtx-machineoverview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-gtx.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/gtx-dims.jpg"
import RideOnTBTJ from "../../../static/FC-RIDER-YBTJ-LANDSCAPE.pdf"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/gtx-33c-1.jpg"
import CylindricalThumb2Image from "../../components/individualProductPage/images/gtx-33c-4.jpg"
import CylindricalThumb3Image from "../../components/individualProductPage/images/gtx-33c-3.jpg"
import CylindricalThumb4Image from "../../components/individualProductPage/images/gtx-33c-2.jpg"
import CylindricalThumb5Image from "../../components/individualProductPage/images/gtx-33c-5.jpg"
import DiskThumb1 from "../../components/individualProductPage/images/gtx-34d-black-1.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/gtx-34d-black-4.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/gtx-34d-black-3.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/gtx-34d-black-2.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/gtx-34d-black-5.jpg"
//End Thumbnail Images
import "../product-page-styles.scss"

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/images/gtx-33c-1.jpg"
import CylindricalMain2Image from "../../components/individualProductPage/images/gtx-33c-4.jpg"
import CylindricalMain3Image from "../../components/individualProductPage/images/gtx-33c-3.jpg"
import CylindricalMain4Image from "../../components/individualProductPage/images/gtx-33c-2.jpg"
import CylindricalMain5Image from "../../components/individualProductPage/images/gtx-33c-5.jpg"
import DiskMain1Image from "../../components/individualProductPage/images/gtx-34d-black-1.jpg"
import DiskMain2Image from "../../components/individualProductPage/images/gtx-34d-black-4.jpg"
import DiskMain3Image from "../../components/individualProductPage/images/gtx-34d-black-3.jpg"
import DiskMain4Image from "../../components/individualProductPage/images/gtx-34d-black-2.jpg"
import DiskMain5Image from "../../components/individualProductPage/images/gtx-34d-black-5.jpg"
import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"
import OrbitalOne from "../../components/individualProductPage/images/fc-gtx-orbital-deck-view-1.1220.png"
import OrbitalTwo from "../../components/individualProductPage/images/fc-gtx-orbital-deck-view-2.1216.png"
import OrbitalThree from "../../components/individualProductPage/images/fc-gtx-orbital-deck-view-3.1217.png"
import OrbitalFour from "../../components/individualProductPage/images/fc-gtx-orbital-deck-view-4.1218.png"
import OrbitalFive from "../../components/individualProductPage/images/fc-gtx-orbital-deck-view-5.1219.png"
import ControllerImage from "../../components/individualProductPage/images/choose-your-controller-box-riders-v2.jpg"

import { OptionsBar } from "../../components/individualProductPage/FeaturedProduct/FeaturedProductStyles/OptionsBar"
//End Main Images
import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`
const ControllerImageContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`
const SizeBtnContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const GtxProductPage = props => {
  return (
    <>
      <Layout>
        <SEO title="GTX" />
        <Head title="GTX" />

        <IndividualMachine
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          orbitalThumb1={OrbitalFive}
          orbitalThumb2={OrbitalTwo}
          orbitalThumb3={OrbitalThree}
          orbitalThumb4={OrbitalFour}
          orbitalThumb5={OrbitalOne}
          orbitalMain1={OrbitalFive}
          orbitalMain2={OrbitalTwo}
          orbitalMain3={OrbitalThree}
          orbitalMain4={OrbitalFour}
          orbitalMain5={OrbitalOne}
          brochureLink="https://www.dropbox.com/s/u77dwgm4t3xffk6/FC-V2-RIDERS-SM.pdf?dl=0"
          techLink="/fatorycat-rider-v2-techspec.pdf"
          partsLink="/FC-GTX-V2-1-P-EN.pdf"
          opLink="/FC-RIDERS-V2-1-OP-EN.pdf"
          title="GTX v2.1 - Ride On Floor Scrubber"
          textContent="Compact floor scrubbers are used everywhere there are medium and large spaces to clean.  Many machines in the compact ride on scrubber market are light duty and majority plastic, lasting only 3 – 5 years. The GTX sit atop our signature welded steel frame assuring many years of service and durability.  Equipped with simple controls virtually anyone can operate this machine with minimal training. "
          mobileTitle="GTX v2.1 - Ride On Floor Scrubber"
          pageUrl=""
          specifications="Cleaning Path: 25-34 | Run Time: Up To 4.5 Hours | Tank Capacity: Sol: 33 Gal, Rec: 36 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/ride-on-floor-scrubbers/gtr"
          machineName="GTX v2.1"
          downLink="/ride-on-floor-scrubbers/pilot"
          pmLink="/Rider-V2-PM.pdf"
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"
        />

        <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link={RideOnTBTJ}
          />
          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#ride-on-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="GTX v2.1"
          category="Ride On Floor Scrubber"
          path='25 - 34"'
          path2="(63.5 - 86.4 cm)"
          runTime="Up To 4.5 Hours"
          solution="Solution: 33 Gal"
          solutionLiter="(124.9L)"
          recovery="Recovery: 36 Gal"
          recoveryLiter="(136.3L)"
        />
        <h1
          style={{
            margin: "3rem",
            textAlign: "center",
            color: "red",
            fontSize: "3em",
          }}
        >
          Choose Your Controller
        </h1>
        <ControllerImageContainer>
          <img src={ControllerImage} alt="" />
        </ControllerImageContainer>
        <MadeInUSA bgImage={UsaBackground} />

        <div className="spec-title-wrapper">
          <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='52" x 37" x 40'
          weightTitle="Weight (w/o batteries):"
          weight="393 - 443 lbs"
          voltageTitle="System Voltage:"
          voltage="24 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="210 ah WET"
          powerTitle="Power:"
          power="0.75hp"
          tankTitle="Tank Material:"
          tank="Poly 3/8"
        />
        {/* <OptionsBar>Options</OptionsBar>
        <MiniHdOptions /> */}

        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
          <SectionTitle title="Request a Demo" />
        </div>
        <RequestDemoForm image={CylindricalMain1Image} />
      </Layout>
    </>
  )
}

export default GtxProductPage
